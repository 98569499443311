import { Card, CardContent, CardHeader, Grid, Table, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompanyClusterSettingReportValue from 'views/CompanyClusterSettingReportValue';
import { actions } from '_model/company_cluster_setting_report';
import { actions as filterActions } from '_model/filter';
import CompanyClusterSettingReport from '../CompanyClusterSettingReport';

const CompanyClusterSettingReportView = ({ id }) => {

  const item = useSelector(state => state.company_cluster_setting_report.item);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getOne(id));
    let filter = {};
    filter['report_id'] = id;
    dispatch(filterActions.addFilter(filter))
  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={`Report - ${item.name}`} subheader={`#${item.id}`}/>
          <CardContent>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <CompanyClusterSettingReportValue report_id={item.id}></CompanyClusterSettingReportValue>
        
      </Grid>
    </Grid>


  );
}


export default CompanyClusterSettingReportView;
