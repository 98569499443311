/* eslint-disable no-use-before-define */
import React, { useState, useEffect, Fragment } from 'react';
import ZnSelect from './ZnSelect';
import { Select, MenuItem, FormControl, FormLabel, FormGroup, Checkbox, FormHelperText, FormControlLabel } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { configActions } from '_actions/config.actions';
import { leadActions, messageModelActions, attachmentActions, userActions } from '_actions';

export {
  ZnDepartmentSelect,
  ZnDepartmentSimpleSelect,
  ZnDepartmentValue,
  ZnDepartmentMultiSelect,
}


const simpleSelectUseStyles = makeStyles({
  root: {
    //color: 'white',
  },
  select: {
    //color: 'inerith'
  },
  icon: {
    //color: 'white'
  },
})

const ZnDepartmentMultiSelect = (props) => {

  const classes = simpleSelectUseStyles();

  const {
    id,
    label,
    onChange,
    onKeyPress,
    values,
    error,
    helperText
  } = props;

  const [checked, setChecked] = useState([]);

  const isChecked = (code) => {
    return checked.includes(parseInt(code));
  }

  useEffect(() => {
    setChecked(values);
  }, [values]);



  const getDepartments = () => {
    return departments.map((dep) => {
      dep.checked = isChecked(dep);
      return dep;
    });
  }

  const handleChange = (e) => {
    const code = parseInt(e.target.value);
    if (isChecked(code)) {
      var values = checked.filter((value) => { return value !== code; });

    } else {
      var values = [...checked, code];
    }

    setChecked(values);
    onChange(values);

  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Departments</FormLabel>
        <FormGroup>
          {getDepartments().map((dep, k) => (
            <FormControlLabel key={k}
              control={<Checkbox
                // checked={dep.checked}
                checked={isChecked(dep.code)}
                value={dep.code}
                onChange={handleChange}
                name="departments" />} label={dep.label}
            />
          ))}
        </FormGroup>

      </FormControl>
    </div>
  );


}

const ZnDepartmentSimpleSelect = () => {

  const classes = simpleSelectUseStyles();

  const departmentId = useSelector(state => state.config.departmentId);

  const dispatch = useDispatch();
  const handleChange = event => {
    dispatch(configActions.setDepartment(event.target.value));
  };

  // useEffect(() => {
  //   dispatch(leadActions.getAll());
  //   dispatch(messageModelActions.getAll());
  //   dispatch(attachmentActions.getAll());
  //   //dispatch(userActions.getAll());
  // }, [departmentId]);



  return (

    <Select
      classes={{
        root: classes.root,
        select: classes.select,
        icon: classes.icon,
      }}
      value={departmentId}
      onChange={handleChange}
      style={{width:"100%"}}
    >
      <MenuItem value="0">All department</MenuItem>
      {departments.map((dep, k) => {

        return (
          <MenuItem key={k} value={dep.code}>{dep.label}</MenuItem>
        );

      })}
    </Select>

  );
}

const ZnDepartmentSelect = (props) => {

  const {
    id,
    label,
    onChange,
    onKeyPress,
    value,
    error,
    helperText
  } = props;

  return (
    <ZnSelect
      id={id}
      label={label}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      error={error}
      helperText={helperText}
      items={departments}
    />
  );
}

const ZnDepartmentValue = (props) => {

  const { value } = props;

  const [label, setLabel] = useState(null);



  const getLabel = (value) => {
    const code = departments.filter(item => { return item && item.code === value }).shift();
    if (code && code.label) return code.label;

  }
  if (Array.isArray(value)) {
    return (
      <div>
        {value.map((item, k) => (
          <Fragment key={k}>{(k ? ', ' : '') + getLabel(item.toString())}</Fragment>

        ))}
      </div>
    );

  }

  return (
    <Fragment>{getLabel(value)}</Fragment>
  );
}

const departments = [

  { code: "1", label: 'Corporate Service Inbound' },
  { code: "2", label: 'Corporate Service Outbound' },
  { code: "3", label: 'Labour & Payroll' },
  { code: "4", label: 'Tax & Accounting' },
  { code: "5", label: 'Immigration' },
  { code: "60", label: 'Litigation' },
  { code: "100", label: 'Others' }

];
