import React, { useEffect } from 'react';
import {  TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useState } from 'react';
import { actions } from '_model/screenshot';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

const ScreenshotTable = ({rows,  screenshots}) => {

  const dispatch = useDispatch();

  const classes = useStyles();

  const [kpiNames, setKpiNames] = useState([]);

  const [data, setData] = useState([]);

  useEffect(()=> {
    if (!screenshots) return;
    if (!Array.isArray(screenshots.items)) return;
    if (screenshots.items.length === 0) return;
    const kn = screenshots.items[0].kpis.map((k)=> k.name);
    setKpiNames(kn);


    console.log(screenshots.items);
    setData(screenshots.items);

  }, [screenshots]);

  const handleChange = (event) => {
    event.persist();

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    const chunks = event.target.name.split('_');

    dispatch(actions.save({
      kpi_id: chunks.pop(),
      company_id : chunks.pop(),
      period_id : chunks.pop(),
      kpi_value: event.target.value,
    }));
  };

  
  return (
   
            <TableContainer component={Paper}>
              
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Period</TableCell>
                    <TableCell>Company</TableCell>
                    {kpiNames.map((v,k)  => (
                      <TableCell key={k} >{v}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, k) => (
                    <TableRow key={k}>
                      <TableCell component="th" scope="row">
                        {item.period_name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {item.company_name}
                      </TableCell>
                      { item.kpis.map((kpi,k)=> (
                        <TableCell key={k} align="right">
                          <TextField 
                            name={`field_${item.period_id}_${item.company_id}_${kpi.id}`} 
                            variant="outlined" 
                            defaultValue={kpi.value} 
                            //onChange={handleChange}
                            
                            onKeyPress={handleChange}
                            // onBlur={handleChange}
                            />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
         
  );
};

export default ScreenshotTable;



