export const constants = {

    STATUS_DRAFT: 'CONFIG_PERIOD_STATUS_DRAFT',
    STATUS_OUTGOING: 'CONFIG_PERIOD_STATUS_OUTGOING',
    STATUS_SENT: 'CONFIG_PERIOD_STATUS_SENT',
    STATUS_ERROR: 'CONFIG_PERIOD_STATUS_ERROR',



    DIRECTION_INCOMING: "CONFIG_PERIOD_DIRECTION_INCOMING",
    DIRECTION_OUTGOING: "CONFIG_PERIOD_DIRECTION_OUTGOING",

    ACTION_SAVE: 'CONFIG_PERIOD_ACTION_SAVE',

    SAVE_REQUEST: 'CONFIG_PERIOD_SAVE_REQUEST',
    SAVE_SUCCESS: 'CONFIG_PERIOD_SAVE_SUCCESS',
    SAVE_FAILURE: 'CONFIG_PERIOD_SAVE_FAILURE',
   
    GETALL_REQUEST: 'CONFIG_PERIOD_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONFIG_PERIOD_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONFIG_PERIOD_GETALL_FAILURE',

    GETONE_REQUEST: 'CONFIG_PERIOD_GETONE_REQUEST',
    GETONE_SUCCESS: 'CONFIG_PERIOD_GETONE_SUCCESS',
    GETONE_FAILURE: 'CONFIG_PERIOD_GETONE_FAILURE',

    DELETE_REQUEST: 'CONFIG_PERIOD_DELETE_REQUEST',
    DELETE_SUCCESS: 'CONFIG_PERIOD_DELETE_SUCCESS',
    DELETE_FAILURE: 'CONFIG_PERIOD_DELETE_FAILURE',

    SHOW_NEW : 'CONFIG_PERIOD_SHOW_NEW',
    CLOSE_DETAIL : 'CONFIG_PERIOD_CLOSE_DETAIL',
};
