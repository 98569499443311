import React from 'react';
import { useParams } from 'react-router';
import CompanyList, { CompanyDetail, CompanyView } from './components';


const Company = () => {

  const { company_id } = useParams();
  return (
    <>
      {company_id ? (
         <CompanyView id={company_id} />
      ) : (
          <>
            <CompanyList />
            <CompanyDetail />
          </>
        )}

    </>
  );
};

export default Company;
