import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions, actions as companyActions } from '../../../_model/company/actions';
import {  Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import ItemList from 'common/ItemList';
import Toolbar from 'common/Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  },
  footerAppBar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)'
    },
    top: 'auto',
    bottom: 0,
  },
}));



const CompanyList = () => {
  const classes = useStyles();
  const company = useSelector(state => state.company);
  const dispatch = useDispatch();
  const companySearchText = null;
  const companySearchStatuses = [];
 

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);
  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

 
  useEffect(() => {
    dispatch(companyActions.getAll(companySearchStatuses, companySearchText, '', false, page +1, rowsPerPage));
  }, []);


  // item mapping

  

  const itemMapper = (item) => {
    return {
      id: item.id,
      action :  "/companies/" + item.id,
      primary: `(${item.cluster_id}) #${item.id} ${item.name}`,
      secondary: item.website,
    };
  }

  

  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={4}>
    <Grid item xs={12}>

      <Card>
        
        <CardHeader title="Company" action={<Toolbar actions={actions} />} ></CardHeader>
        <CardContent>
        <ItemList items={company.items ?? []} itemMapper={itemMapper} 
          onClick={handleClick}
          actionPath="/company/"/>
     </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyList;



