import React from 'react';
import { useParams } from 'react-router';
import ConfigPeriodList, { ConfigPeriodDetail, ConfigPeriodView } from './components';

const ConfigPeriod = () => {

  const { config_period_id } = useParams();

  return (
    <>
    {config_period_id ? ( 
      <ConfigPeriodView id={config_period_id} />
    ) : (
      <>
      <ConfigPeriodList />
      <ConfigPeriodDetail />
      </>
    )}
      
    </>
  );

};

export default ConfigPeriod;
