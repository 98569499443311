import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../_model/company_cluster_setting_multicard_kpi/actions';
import { AppBar, Card, CardContent, CardHeader, Grid, TablePagination, Toolbar } from '@material-ui/core';
import ItemList from 'common/ItemList';
import { configActions } from '_actions/config.actions';
import { CompanyClusterSettingMulticardKpiToolbar } from '.';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  content: {
    marginTop: theme.spacing(0)
  },
  footerAppBar: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 240px)'
    },
    top: 'auto',
    bottom: 0,
  },
}));



const CompanyClusterSettingMulticardKpiList = () => {
  
  const company_cluster_setting_multicard_kpi = useSelector(state => state.company_cluster_setting_multicard_kpi);
  
  const dispatch = useDispatch();
  


 
  useEffect(() => {
    dispatch(actions.getAll([], 1, 1000));
  }, []);


  // item mapping



  const itemMapper = (item) => {
    return {
      id: item.id,
      action :  "/company-cluster-setting-multicard-kpi/" + item.id,
      primary: `${item.name} - ${item.weight}`,
      secondary: `#${item.id} (${item.subcategory_id})`,
    };
  }


  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>
      <Card>
        <CardHeader title="PPP MulticardKpi" action={<CompanyClusterSettingMulticardKpiToolbar />} ></CardHeader>
        <CardContent>
        <ItemList items={company_cluster_setting_multicard_kpi.items ?? []} itemMapper={itemMapper} 
        onClick={handleClick}
         actionPath="/company_cluster_setting_multicard_kpi/"/>
      </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterSettingMulticardKpiList;



