import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../_model/company_cluster_setting_report_value/actions';

import { Card, CardContent, CardHeader, Grid, TextField } from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const CompanyClusterSettingReportValueList = ({id}) => {

  const classes = useStyles();

  const company_cluster_setting_report = useSelector(state => state.company_cluster_setting_report);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      console.log("REP2", id);
      dispatch(actions.getOne(id));
    }
  }, [id]);



  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="ReportValue" ></CardHeader>
          <CardContent>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell align="right">Calories</TableCell>
                    <TableCell align="right">Fat&nbsp;(g)</TableCell>
                    <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell align="right">Calories</TableCell>
                    <TableCell align="right">Fat&nbsp;(g)</TableCell>
                    <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell>Dessert (100g serving)</TableCell>
                    <TableCell align="right">Calories</TableCell>
                    <TableCell align="right">Fat&nbsp;(g)</TableCell>
                    <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.calories} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.fat} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.carbs} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.calories} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.fat} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.carbs} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.calories} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.fat} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.carbs} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                      <TableCell align="right"><TextField variant="outlined" value={row.protein} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterSettingReportValueList;



