import { constants  } from './constants';
import { service } from './service';


export const actions = {
    addFilter,
    removeFilter,
    
};


function addFilter(filter= {}) {
    return { type: constants.ADD_FILTER, filter }
}

function removeFilter(filterName = '') {
    return { type: constants.REMOVE_FILTER, filterName }
}

