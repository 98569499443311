import { constants } from './constants';

const initialStatus = {
  topbar: null,
};

export function reducer(state = initialStatus, action) {

  switch (action.type) {
    case constants.SET_TOOLBAR:
      return {
        ...state,
        toolbar: action.toolbar
      };
    
    default:
      return state;
  }
}