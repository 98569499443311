import React from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingCategoryList, { CompanyClusterSettingCategoryDetail, CompanyClusterSettingCategoryView } from './components';

const CompanyClusterSettingCategory = () => {

  const { company_cluster_setting_category_id } = useParams();

  return (
    <>
    {company_cluster_setting_category_id ? ( 
      <CompanyClusterSettingCategoryView id={company_cluster_setting_category_id} />
    ) : (
      <>
      <CompanyClusterSettingCategoryList />
      <CompanyClusterSettingCategoryDetail />
      </>
    )}
      
    </>
  );

};

export default CompanyClusterSettingCategory;
