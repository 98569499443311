import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import  { AgencyDetail, AgencyView } from './components';
import List from 'common/List';

import { actions } from '../../_model/agency/actions';


const Agency = () => {

  const { agency_id } = useParams();
  const title = 'Agency';
  const filter_param = 'agency_id';

  const repo = useSelector(state => state.agency);

  const itemMapper = (item) => {
    return {
      id: item.id,
      action :  "/agencies/" + item.id,
      primary: `#${item.id} ${item.name}`,
      secondary: item.website,
    };
  }



  return (
    <>
      {agency_id ? (
         <AgencyView title={title} id={agency_id} actions={actions} filter_param={filter_param}/>
      ) : (
          <>
            <List repo={repo} itemMapper={itemMapper} title={title}  actions={actions} />
            <AgencyDetail title={title}  actions={actions} />
          </>
        )}

    </>
  );
};

export default Agency;
