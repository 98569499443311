import React, { useEffect, useState } from 'react';
import { actions } from '../../../_model/company_cluster_setting/actions';

import { useDispatch, useSelector } from 'react-redux';
import {  Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import ItemList from 'common/ItemList';
import Toolbar from 'common/Toolbar';





const CompanyClusterSettingList = () => {
  
  const company_cluster_setting = useSelector(state => state.company_cluster_setting);
  const dispatch = useDispatch();
  const company_clusterSearchText = null;
  const company_clusterSearchStatuses = [];
 

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);
  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

 
  useEffect(() => {
    dispatch(actions.getAll(company_clusterSearchStatuses, company_clusterSearchText, '', false, page +1, rowsPerPage));
  }, []);


  // item mapping

  const itemMapper = (item) => {
    return {
      id: item.id,
      action :  "/company-cluster-setting/" + item.id,
      primary: `(${item.cluster_id}) #${item.id} ${item.name}`,
      secondary: item.website,
    };
  }

  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>

      <Card>
        <CardHeader title="Settings" action={<Toolbar actions={actions} />} ></CardHeader>
        <CardContent>
        <ItemList items={company_cluster_setting.items ?? []} itemMapper={itemMapper} 
        onClick={handleClick}
         actionPath="/company_cluster_setting/"/>
      </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterSettingList;



