import { constants } from './constants';
import { service } from './service';


export const actions = {
    save,
    getAll,
    getOne,
    _delete,
    showNew,
    closeDetail,
};


function showNew(id = 0) {
    return { type: constants.SHOW_NEW, id }
}

function closeDetail() {
    return { type: constants.CLOSE_DETAIL }
}


function _delete(id) {



    return dispatch => {
        dispatch(request());
        service._delete(id)
            .then(
                items => dispatch(getAll()),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: constants.DELETE_REQUEST }
    }
    function success(id) {
        return { type: constants.DELETE_SUCCESS, id }
    }
    function failure(id, error) {
        return { type: constants.DELETE_FAILURE, id, error }
    }
}


function save(data) {

    return (dispatch, getState) => {
        
        const {filters} = getState().filter;

        const item = {...data, ...filters};

        
        dispatch(request());

        service.save(item)
            .then(
                item => {
                    dispatch(getAll());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: constants.SAVE_REQUEST }
    }
    function success(item) {
        console.log(item);
        return { type: constants.SAVE_SUCCESS, item }
    }
    function failure(error) {
        return { type: constants.SAVE_FAILURE, ...error };
    }


}

function getAll( page = '', per_page = '') {

    return (dispatch, getState) => {
        
        const {filters} = getState().filter;

        dispatch(request());

        service.getAll(filters, page, per_page)
            .then(
                items => {
                    dispatch(success(items));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return { type: constants.GETALL_REQUEST }
    }
    function success(items) {
        return { type: constants.GETALL_SUCCESS, items }
    }
    function failure(error) {
        return { type: constants.GETALL_FAILURE, ...error };
    }

}

function getOne(id) {


    return dispatch => {
        dispatch(request());

        service.getById(id)
            .then(
                item => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return { type: constants.GETONE_REQUEST }
    }
    function success(item) {
        return { type: constants.GETONE_SUCCESS, item }
    }
    function failure(error) {
        return { type: constants.GETONE_FAILURE, id, error }
    }
}
