import React from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingReportList, { CompanyClusterSettingReportDetail, CompanyClusterSettingReportView } from './components';

const CompanyClusterSettingReport = () => {

  const { company_cluster_setting_report_id } = useParams();

  return (
    <>
    {company_cluster_setting_report_id ? ( 
      <CompanyClusterSettingReportView id={company_cluster_setting_report_id} />
    ) : (
      <>
      <CompanyClusterSettingReportList />
      <CompanyClusterSettingReportDetail />
      </>
    )}
      
    </>
  );

};

export default CompanyClusterSettingReport;
