export const constants = {

    STATUS_DRAFT: 'COMPANY_CLUSTER_SETTING_REPORT_STATUS_DRAFT',
    STATUS_OUTGOING: 'COMPANY_CLUSTER_SETTING_REPORT_STATUS_OUTGOING',
    STATUS_SENT: 'COMPANY_CLUSTER_SETTING_REPORT_STATUS_SENT',
    STATUS_ERROR: 'COMPANY_CLUSTER_SETTING_REPORT_STATUS_ERROR',



    DIRECTION_INCOMING: "COMPANY_CLUSTER_SETTING_REPORT_DIRECTION_INCOMING",
    DIRECTION_OUTGOING: "COMPANY_CLUSTER_SETTING_REPORT_DIRECTION_OUTGOING",

    ACTION_SAVE: 'COMPANY_CLUSTER_SETTING_REPORT_ACTION_SAVE',

    SAVE_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_SAVE_REQUEST',
    SAVE_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_SAVE_SUCCESS',
    SAVE_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_SAVE_FAILURE',
   
    GETALL_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_GETALL_FAILURE',

    GETONE_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_GETONE_REQUEST',
    GETONE_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_GETONE_SUCCESS',
    GETONE_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_GETONE_FAILURE',

    DELETE_REQUEST: 'COMPANY_CLUSTER_SETTING_REPORT_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMPANY_CLUSTER_SETTING_REPORT_DELETE_SUCCESS',
    DELETE_FAILURE: 'COMPANY_CLUSTER_SETTING_REPORT_DELETE_FAILURE',

    SHOW_NEW : 'COMPANY_CLUSTER_SETTING_REPORT_SHOW_NEW',
    CLOSE_DETAIL : 'COMPANY_CLUSTER_SETTING_REPORT_CLOSE_DETAIL',
};
