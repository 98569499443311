import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Screenshot } from 'views';
import { actions } from '_model/company';
import { actions as filterActions } from '_model/filter';


const CompanyView = ({ id }) => {

  const item = useSelector(state => state.company.item);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getOne(id));
    let filter = {};
    filter['company_id'] = id;
    dispatch(filterActions.addFilter(filter))

    return () => {
      dispatch(filterActions.removeFilter('company_id'));
    };
  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Company" />
          <CardContent>
          <Typography>{item.name}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Screenshot></Screenshot>
      </Grid>

      
    </Grid>


  );
}


export default CompanyView;
