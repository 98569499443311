import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../helpers';

export const userActions = {
    save,
    updatePassword,
    getAll,
    getOne,
    login,
    logout,
    recover,
    _delete,
};


function updatePassword(password) {

    return dispatch => {

        dispatch(request());

        userService.updatePassword(password)
            .then(
                result => {
                    dispatch(alertActions.success('Password Updated'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
       
            
    };

    function request() {
        return { type: userConstants.KICK_REQUEST };
    }
    function success(user) {
        return { type: userConstants.KICK_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.KICK_FAILURE, error };
    }
}


function save(user) {

    return dispatch => {

        dispatch(request());

        userService.doAction(user.id, userConstants.ACTION_SAVE, user)
            .then(
                result => {
                    dispatch(alertActions.success('User Saved'));
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
       
            
    };

    function request() {
        return { type: userConstants.KICK_REQUEST };
    }
    function success(user) {
        return { type: userConstants.KICK_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.KICK_FAILURE, error };
    }
}

function getAll(text = '', page = '', per_page = '') {
    return (dispatch, getState) => {
        const departmentId = null;

        dispatch(request());

        userService.getAll([departmentId], text, page, per_page)
            .then(
                users => {
                    dispatch(success(users.data, users.meta));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };

    function request() { 
        return { type: userConstants.GETALL_REQUEST } 
    }
    function success(users, meta) { 
        return { type: userConstants.GETALL_SUCCESS, users, meta} 
    }
    function failure(error) { 
        return { type: userConstants.GETALL_FAILURE, error } 
    }
}

function getOne(id) {
    
    return dispatch => {
        dispatch(request());

        userService.getById(id)
            .then(
                user => dispatch(success(user.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: userConstants.GETONE_REQUEST } 
    }
    function success(user) { 
        return { type: userConstants.GETONE_SUCCESS, user } 
    }
    function failure(error) { 
        return { type: userConstants.GETONE_FAILURE, id, error } 
    }
}

function _delete(id) {
    
    return dispatch => {
        dispatch(request());

        userService._delete(id)
            .then(
                user => dispatch(getAll()),
                //user => dispatch(success(user.data)),
                error => dispatch(failure(error))
            );
    };

    function request() { 
        return { type: userConstants.DELETE_REQUEST } 
    }
    // function success(user) { 
    //     return { type: userConstants.DELETE_SUCCESS, user } 
    // }
    function failure(error) { 
        return { type: userConstants.DELETE_FAILURE, id, error } 
    }
}



function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(getAll());
                    dispatch(success(user))
                    history.push('/dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function recover(username) {
    return dispatch => {
        dispatch(request({ username }));

        userService.recover(username)
            .then(
                user => { 
                    dispatch(success(user))
                    history.push('/login');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.RECOVER_REQUEST, user } }
    function success(user) { return { type: userConstants.RECOVER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.RECOVER_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}
