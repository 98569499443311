import { combineReducers } from 'redux';

import {reducer as user} from './user/reducer';
import {reducer as authentication} from './authentication/reducer';
import {reducer as alert} from './alert/reducer';
import {reducer as company} from './company/reducer';
import {reducer as project} from './project/reducer';
import {reducer as agency} from './agency/reducer';
import {reducer as topbar} from './topbar/reducer';
import {reducer as filter} from './filter/reducer';
import {reducer as company_cluster} from './company_cluster/reducer';
import {reducer as company_cluster_setting} from './company_cluster_setting/reducer';
import {reducer as company_cluster_setting_category} from './company_cluster_setting_category/reducer';
import {reducer as company_cluster_setting_subcategory} from './company_cluster_setting_subcategory/reducer';
import {reducer as company_cluster_setting_kpi} from './company_cluster_setting_kpi/reducer';
import {reducer as company_cluster_setting_multicard} from './company_cluster_setting_multicard/reducer';
import {reducer as company_cluster_setting_multicard_kpi} from './company_cluster_setting_multicard_kpi/reducer';
import {reducer as company_cluster_setting_report} from './company_cluster_setting_report/reducer';
import {reducer as company_cluster_setting_report_value} from './company_cluster_setting_report_value/reducer';
import {reducer as config_kpi} from './config_kpi/reducer';
import {reducer as config_period} from './config_period/reducer';
import {reducer as screenshot} from './screenshot/reducer';





const rootReducer = combineReducers({
  user,
  authentication,
  alert,
  agency,
  company_cluster,
  company_cluster_setting,
  company_cluster_setting_category,
  company_cluster_setting_subcategory,
  company_cluster_setting_kpi,
  company_cluster_setting_multicard,
  company_cluster_setting_multicard_kpi,
  company_cluster_setting_report,
  company_cluster_setting_report_value,
  company,
  project,
  topbar,
  filter,
  config_kpi,
  config_period,
  screenshot,
});



export default rootReducer;