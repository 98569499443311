import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyClusterSettingMulticard } from 'views';
import CompanyClusterSettingSubcategory from 'views/CompanyClusterSettingSubcategory';
import { actions } from '_model/company_cluster_setting_category';
import { actions as filterActions } from '_model/filter';

const CompanyClusterSettingCategoryView = ({ id }) => {

  const item = useSelector(state => state.company_cluster_setting_category.item);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getOne(id));
    let filter = {};
    filter['category_id'] = id;
    dispatch(filterActions.addFilter(filter))
  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Category" />
          <CardContent>
          <Typography>#{item.id} {item.name} - {item.weight}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <CompanyClusterSettingSubcategory></CompanyClusterSettingSubcategory>
      </Grid>

      <Grid item xs={12}>
        <CompanyClusterSettingMulticard></CompanyClusterSettingMulticard>
      </Grid>
    </Grid>


  );
}


export default CompanyClusterSettingCategoryView;
