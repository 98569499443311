import { authHeader } from '../../helpers';
import handleResponse from '../handleResponse';


const endpoint = process.env.REACT_APP_API_ENDPOINT + `/api/company-cluster/`;

export const service = {
    save,
    getAll,
    getById,
    _delete,
};

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint + id, requestOptions).then(handleResponse);
}


function save(data) {

    let method = 'POST';
    let urlId = '';

    if (data.id > 0) {
        method = 'PUT';
        urlId = data.id;
    }

    const requestOptions = {
        method: method,
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint + urlId, requestOptions).then(handleResponse);
}


function getAll(params={}, page = '', per_page = '') {
    

    console.log("API enpoint:", endpoint);
    const url = new URL(endpoint);
    url.search = new URLSearchParams({...params, page, per_page}).toString();
    
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(url, requestOptions).then(handleResponse);

}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint + id, requestOptions).then(handleResponse);
}
