import React, { Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Modal, Dialog, DialogTitle, List, ListItem, DialogActions, Button, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import FilterIcon from '@material-ui/icons/FilterList';
import InputIcon from '@material-ui/icons/Input';
import SettingsIcon from '@material-ui/icons/Settings';
import { CurrentPageName } from '../Sidebar/Sidebar';
import { ZnDepartmentSimpleSelect } from 'common/znFields/ZnDepartmentSelect';
import { ZnUserSimpleSelect } from 'common/znFields/ZnUserSelect';
import { useState } from 'react';



const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: 'gray',
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  fabProgress: {
    color: "#FFFFFF",
  }
}));

const UtilityBar = props => {
  const { className} = props;

  const classes = useStyles();

  
  return (
    <Box className={classes.root}>pinco pallo</Box>
  );
};

UtilityBar.propTypes = {
  className: PropTypes.string,
};

export default UtilityBar;
