import React from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingList, { CompanyClusterSettingDetail, CompanyClusterSettingView } from './components';

const CompanyClusterSetting = () => {

  const { company_cluster_setting_id } = useParams();

  return (
    <>
    {company_cluster_setting_id ? ( 
      <CompanyClusterSettingView id={company_cluster_setting_id} />
    ) : (
      <>
      <CompanyClusterSettingList />
      <CompanyClusterSettingDetail />
      </>
    )}
      
    </>
  );

};

export default CompanyClusterSetting;
