export const constants = {

    
    SAVE_REQUEST: 'SCREENSHOT_SAVE_REQUEST',
    SAVE_SUCCESS: 'SCREENSHOT_SAVE_SUCCESS',
    SAVE_FAILURE: 'SCREENSHOT_SAVE_FAILURE',
   
    GETALL_REQUEST: 'SCREENSHOT_GETALL_REQUEST',
    GETALL_SUCCESS: 'SCREENSHOT_GETALL_SUCCESS',
    GETALL_FAILURE: 'SCREENSHOT_GETALL_FAILURE',

    GETONE_REQUEST: 'SCREENSHOT_GETONE_REQUEST',
    GETONE_SUCCESS: 'SCREENSHOT_GETONE_SUCCESS',
    GETONE_FAILURE: 'SCREENSHOT_GETONE_FAILURE',

    DELETE_REQUEST: 'SCREENSHOT_DELETE_REQUEST',
    DELETE_SUCCESS: 'SCREENSHOT_DELETE_SUCCESS',
    DELETE_FAILURE: 'SCREENSHOT_DELETE_FAILURE',

    SHOW_NEW : 'SCREENSHOT_SHOW_NEW',
    CLOSE_DETAIL : 'SCREENSHOT_CLOSE_DETAIL',
};
