import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ZnTextField from 'common/znFields/ZnTextField';
import { useState } from 'react';
import { ZnDepartmentMultiSelect } from 'common/znFields/ZnDepartmentSelect';
import { userActions } from '_actions';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';



const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  
};


const UserDetail = props => {

  const { open, setOpen , user } = props;
 
  const dispatch = useDispatch();

  const handleChange = (event)  => {
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
    ? event.target.checked
    : event.target.value);
  };

  const departmentsHandleChange = ( values) => {
    handleFieldChange('departments',values );
  };

  const handleFieldChange = (fieldName, value)  => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]:value,
          
      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {

    if (!formState.isValid) return;

    if(event.type === 'keypress' && event.key !== 'Enter'){
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));
 
    
    dispatch(userActions.save(formState.values));
  };

  const handleDelete = event => {


    if (formState.values.id) {
      dispatch(userActions._delete(formState.values.id));
    }
    setOpen(false);
 
    
    
  };

  const doingAction = useSelector(state => state.users.doingAction);
  const successAction = useSelector(state => state.users.successAction);
  const actionErrorMsg = useSelector(state => state.users.actionErrorMsg);

  useEffect(() => {
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if( !doingAction ){
      setOpen(false);
      setFormState(
        {
          loaded: false,
          isValid: false,
          values: {
            name: '',
            email: '',
            departments: [],
          },
          touched: {},
          errors: {}
        }
      );
    }
  };

  const [formState, setFormState] = useState({
    loaded: false,
    isValid: false,
    values: {
      name: '',
      email: '',
      departments: [],
    },
    touched: {},
    errors: {}
  });

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {

    if (user) {
      setFormState({
        ...formState,
        values: user,
      });
    } else {
      setFormState({
        loaded: false,
        isValid: false,
        values: {
          name: '',
          email: '',
          departments: [],
        },
        touched: {},
        errors: {}
      });
    }
  }, [user, open]);


  useEffect(() => {
    setFormState(formState  => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);

  const hasError = (field) => {
    if(!formState.errors[field]) return false;
    return formState.errors[field] ? true : false;
  }

  const getError = (field) => {
    if(!formState.errors[field]) return null;

    return formState.errors[field] && formState.errors[field][0];
  }

 

  return (
    <div>
    
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {user ? "User detail" : "New user"}
        </DialogTitle>
        <DialogContent>
          <ZnTextField
            onKeyPress={handleSubmit} 
            name="name"
            label="Name"
            onChange={handleChange}
            value={formState.values.name || ""}
            error={hasError('name')}
            helperText={
              getError('name')
            }
            margin="normal"
          />
          <ZnTextField
            onKeyPress={handleSubmit} 
            name="email"
            label="Email"
            onChange={handleChange}
            value={formState.values.email}
            error={hasError('email')}
            helperText={
              getError('email')
            }
            margin="normal"
            variant="outlined"
          />
          <ZnDepartmentMultiSelect
            id="departments"
            label="Departments"
            onChange={departmentsHandleChange}
            onKeyPress={handleSubmit} 
            values={formState.values.departments || []}
            error={hasError('departments')}
            helperText={
              getError('departments')
            }
          />
          
          <DialogTitle style={{marginTop:"30px"}}>Email Sign Informations</DialogTitle>
          <ZnTextField
            onKeyPress={handleSubmit} 
            name="sign_name"
            label="Sign Name"
            onChange={handleChange}
            value={formState.values.sign_name || ""}
            error={hasError('sign_name')}
            helperText={
              getError('sign_name')
            }
            margin="normal"
            variant="outlined"
          />
          <ZnTextField
            onKeyPress={handleSubmit} 
            name="sign_role"
            label="Sign Role"
            onChange={handleChange}
            value={formState.values.sign_role || ""}
            error={hasError('sign_role')}
            helperText={
              getError('sign_role')
            }
            margin="normal"
            variant="outlined"
          />
           <ZnTextField
            onKeyPress={handleSubmit} 
            name="sign_email"
            label="Sign Email"
            onChange={handleChange}
            value={formState.values.sign_email || ""}
            error={hasError('sign_email')}
            helperText={
              getError('sign_email')
            }
            margin="normal"
            variant="outlined"
          />
          <ZnTextField
            onKeyPress={handleSubmit} 
            name="sign_phone"
            label="Sign Phone"
            onChange={handleChange}
            value={formState.values.sign_phone || ""}
            error={hasError('sign_phone')}
            helperText={
              getError('sign_phone')
            }
            margin="normal"
            variant="outlined"
          />
           <ZnTextField
            onKeyPress={handleSubmit} 
            name="sign_mobile"
            label="Sign Mobile"
            onChange={handleChange}
            value={formState.values.sign_mobile || ""}
            error={hasError('sign_mobile')}
            helperText={
              getError('sign_mobile')
            }
            margin="normal"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions >
          <Button   onClick={handleClose} >
            Cancel
          </Button>
          <Button   onClick={handleSubmit} >
            Save
          </Button>
          <Button   onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

UserDetail.defaultProps = {
  open: false,
  user: {},
}
export default UserDetail;
