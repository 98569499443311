import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions, actions as company_clusterActions } from '../../../_model/company_cluster/actions';
import {  Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import ItemList from 'common/ItemList';
import Toolbar from 'common/Toolbar';




const CompanyClusterList = ({agency_id}) => {
  
  const company_cluster = useSelector(state => state.company_cluster);
  const dispatch = useDispatch();
  
  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };


  useEffect(() => {
    dispatch(company_clusterActions.getAll({agency_id:agency_id}, page + 1, rowsPerPage));
  }, []);


  // item mapping



  const itemMapper = (item) => {
    return {
      id: item.id,
      action :  "/company-cluster/" + item.id,
      primary: `(${item.agency_id}) #${item.id} ${item.name}`,
      secondary: item.website,
    };
  }


  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Clusters" action={<Toolbar actions={actions} />}></CardHeader>
          <CardContent>
            <ItemList items={company_cluster.items ?? []} itemMapper={itemMapper}
              onClick={handleClick}
              actionPath="/company_cluster/" />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterList;



