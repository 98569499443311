import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompanyClusterSettingCategory from 'views/CompanyClusterSettingCategory';
import CompanyClusterSettingMulticard from 'views/CompanyClusterSettingMulticard';
import CompanyClusterSettingReport from 'views/CompanyClusterSettingReport';
import { actions } from '_model/company_cluster_setting';
import { actions as filterActions } from '_model/filter';

const CompanyClusterSettingView = ({ id }) => {

  const item = useSelector(state => state.company_cluster_setting.item);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getOne(id));
    let filter = {};
    filter['setting_id'] = id;
    dispatch(filterActions.addFilter(filter))
  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Cluster Setting" />
          <CardContent>
          <Typography>{item.name}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <CompanyClusterSettingCategory></CompanyClusterSettingCategory>
      </Grid>
      
      <Grid item xs={12}>
        <CompanyClusterSettingReport></CompanyClusterSettingReport>
      </Grid>
    </Grid>


  );
}


export default CompanyClusterSettingView;
