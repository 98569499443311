import React from 'react';
import { useParams } from 'react-router';
import ScreenshotList, { ScreenshotDetail, ScreenshotView } from './components';

const Screenshot = () => {

  const { screenshot_id } = useParams();

  return (
    <>
    {screenshot_id ? ( 
      <ScreenshotView id={screenshot_id} />
    ) : (
      <>
      <ScreenshotList />
      <ScreenshotDetail />
      </>
    )}
      
    </>
  );

};

export default Screenshot;
