import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '_model/topbar';
import ProjectList, { ProjectDetail } from './components';


const Project = () => {

  const dispatch = useDispatch();
  useEffect(()=> {
     dispatch(actions.setToolbar('project'));
  }, []);

  return (
    <>
    <ProjectList />
    <ProjectDetail />
    </>
  );
};

export default Project;
