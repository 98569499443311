import { constants } from './constants';

const initialStatus = {
  filters: {agency_id:1},
};

export function reducer(state = initialStatus, action) {


  switch (action.type) {
    case constants.ADD_FILTER:
        return {
          ...state,
          filters: {...state.filters, ...action.filter}
        };

    case constants.REMOVE_FILTER:

        let filters = {...state.filters};
        delete filters[action.filterName];
        
        return {
          ...state,
          filters: filters,
        };
        break;
    default:
      return state;
  }
}