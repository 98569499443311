import { authHeader } from '../../helpers';
import handleResponse from '../handleResponse';


const endpoint = process.env.REACT_APP_API_ENDPOINT + `/api/project/`;
export const service = {
    save,
    getAll,
    getById,
    _delete,
};

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(endpoint + id, requestOptions).then(handleResponse);
}



function save(data) {


    let method = 'POST';
    let urlId = '';

    if (data.id > 0) {
        method = 'PUT';
        urlId = data.id;
    }

    const requestOptions = {
        method: method,
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(endpoint + urlId, requestOptions).then(handleResponse);
}


function getAll(leadStatus = [], text = '', leadId = '', check = false, page = '', per_page = '') {
    if (check) check = "1";
    else check = "";
    let statuses = leadStatus.join();
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint + `?page=${page}&per_page=${per_page}`, requestOptions).then(handleResponse);

}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(endpoint + id, requestOptions).then(handleResponse);
}
