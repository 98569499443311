import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import ItemList from './ItemList';
import Toolbar from './Toolbar';


const List = ({ title, actions, repo, itemMapper }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getAll(1, 100));
  }, []);




  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>

        <Card>
          <CardHeader title={title} action={<Toolbar actions={actions} />} ></CardHeader>
          <CardContent>
            <ItemList items={repo.items ?? []} itemMapper={itemMapper} onClick={handleClick} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default List;



