import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowForwardIos';

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button
} from '@material-ui/core';
import { history } from 'helpers';
import { useDispatch } from 'react-redux';
import { actions } from '_model/project';



const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    fontWeight: "strong",
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2),
    backgroundColor: 'lightgray',

  },
  actions: {
    justifyContent: 'flex-end'
  }
}));




const ItemList = ({ items, itemMapper, onClick }) => {


  const classes = useStyles();



  const handleClick = (item) => {
    if (item.action) {
      history.push(item.action)
    } else {
      if (onClick) onClick(item.id);
    }
  }

  const handleView = (item) => {
    if (item.action) {
      history.push(item.action)
    }
  }

  const handleEdit = (item) => {
    if (onClick) onClick(item.id);
  }

  return (
    <Fragment>
      <List className={classes.listRoot} >
        {items.map(i => {
          const item = itemMapper(i); if (!item) return null;
          return (
            <Fragment key={item.id}>

              <ListItem  >

                <ListItemText
                  classes={{ root: classes.listItem }}
                  primary={item.primary}
                  secondary={item.secondary}
                />
                <ListItemSecondaryAction>
                  <Button color="inherit" onClick={() => handleView(item)}>View</Button>
                  <Button color="inherit" onClick={() => handleEdit(item)}>Edit</Button>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider></Divider>
            </Fragment>
          )
        }
        )}
      </List>
    </Fragment>
  );
};

ItemList.propTypes = {
  className: PropTypes.string,
};

export default ItemList;
