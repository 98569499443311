import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Agency from 'views/Agency';
import CompanyCluster from 'views/CompanyCluster';
import CompanyClusterSetting from 'views/CompanyClusterSetting';
import Company from 'views/Company';
import Project from 'views/Project';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Dashboard as DashboardView,
  SignUp as SignUpView,
  SignIn as SignInView,
  LostPassword as LostPasswordView,
  NotFound as NotFoundView,
  CompanyClusterSettingCategory,
  CompanyClusterSettingSubcategory,
  ConfigKpi,
  ConfigPeriod,
  CompanyClusterSettingMulticard,
  CompanyClusterSettingReport,
} from './views';


const Routes = () => {
  return  (
    <Switch>
      
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
        protected="true"
      />
      <RouteWithLayout
        component={Agency}
        
        layout={MainLayout}
        path="/agencies/:agency_id?"
        protected="true"
      />
      
      <RouteWithLayout
        component={Project}
        exact
        layout={MainLayout}
        path="/projects/:project_id?"
        protected="true"
      />
      
      <RouteWithLayout
        component={CompanyCluster}
        exact
        layout={MainLayout}
        path="/company-cluster/:company_cluster_id?"
        protected="true"
      />
      <RouteWithLayout
        component={CompanyClusterSetting}
        exact
        layout={MainLayout}
        path="/company-cluster-setting/:company_cluster_setting_id?"
        protected="true"
      />
      <RouteWithLayout
        component={CompanyClusterSettingCategory}
        exact
        layout={MainLayout}
        path="/company-cluster-setting-category/:company_cluster_setting_category_id?"
        protected="true"
      />
      <RouteWithLayout
        component={CompanyClusterSettingSubcategory}
        exact
        layout={MainLayout}
        path="/company-cluster-setting-subcategory/:company_cluster_setting_subcategory_id?"
        protected="true"
      />
      <RouteWithLayout
        component={CompanyClusterSettingMulticard}
        exact
        layout={MainLayout}
        path="/company-cluster-setting-multicard/:company_cluster_setting_multicard_id?"
        protected="true"
      />
      <RouteWithLayout
        component={CompanyClusterSettingReport}
        exact
        layout={MainLayout}
        path="/company-cluster-setting-report/:company_cluster_setting_report_id?"
        protected="true"
      />
      <RouteWithLayout
        component={ConfigKpi}
        exact
        layout={MainLayout}
        path="/config-kpi/:config_kpi_id?"
        protected="true"
      />
      <RouteWithLayout
        component={ConfigPeriod}
        exact
        layout={MainLayout}
        path="/config-period/:config_period_id?"
        protected="true"
      />
      <RouteWithLayout
        component={Company}
        exact
        layout={MainLayout}
        path="/companies/:company_id?"
        protected="true"
      />
     



     
     
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={LostPasswordView}
        exact
        layout={MinimalLayout}
        path="/lost-password"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect
        exact
        from="*"
        to={`/dashboard`}
      />
      <Redirect to={`/not-found`} />
    </Switch>
  );
};

export default Routes;
