import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CompanyClusterSettingKpi from 'views/CompanyClusterSettingKpi';
import { CompanyClusterSettingKpiMultidetail } from 'views/CompanyClusterSettingKpi/components';
import { actions } from '_model/company_cluster_setting_subcategory';
import { actions as filterActions } from '_model/filter';

const CompanyClusterSettingSubcategoryView = ({ id }) => {

  const item = useSelector(state => state.company_cluster_setting_subcategory.item);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getOne(id));
    let filter = {};
    filter['subcategory_id'] = id;
    dispatch(filterActions.addFilter(filter))
  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Subcategory" />
          <CardContent>
          <Typography>#{item.id} {item.name} - {item.weight}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <CompanyClusterSettingKpiMultidetail></CompanyClusterSettingKpiMultidetail>
      </Grid>
    </Grid>


  );
}


export default CompanyClusterSettingSubcategoryView;
