import React from 'react';
import { useParams } from 'react-router';
import ConfigKpiList, { ConfigKpiDetail, ConfigKpiView } from './components';

const ConfigKpi = () => {

  const { config_kpi_id } = useParams();

  return (
    <>
    {config_kpi_id ? ( 
      <ConfigKpiView id={config_kpi_id} />
    ) : (
      <>
      <ConfigKpiList />
      <ConfigKpiDetail />
      </>
    )}
      
    </>
  );

};

export default ConfigKpi;
