import React from 'react';
import CompanyCluster from 'views/CompanyCluster';


const Dashboard = () => {
  

 


  return (
    <CompanyCluster></CompanyCluster>
  );
};

export default Dashboard;
