import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingReportValueList, { CompanyClusterSettingReportValueDetail, CompanyClusterSettingReportValueView } from './components';

const CompanyClusterSettingReportValue = ({report_id}) => {

  const { company_cluster_setting_report_value_id } = useParams();

  const [id, setId] = useState();

  useEffect(()=> {
    setId(company_cluster_setting_report_value_id);
  }, [company_cluster_setting_report_value_id]);

  useEffect(()=> {
    console.log("REP", report_id);
    setId(report_id);
  }, [report_id]);

  return (
    <>
    {company_cluster_setting_report_value_id ? ( 
      <CompanyClusterSettingReportValueView id={id} />
    ) : (
      <>
        <CompanyClusterSettingReportValueList id={id} />
      </>
    )}
      
    </>
  );

};

export default CompanyClusterSettingReportValue;
