export const constants = {

    STATUS_DRAFT: 'ACENCY_STATUS_DRAFT',
    STATUS_OUTGOING: 'ACENCY_STATUS_OUTGOING',
    STATUS_SENT: 'ACENCY_STATUS_SENT',
    STATUS_ERROR: 'ACENCY_STATUS_ERROR',



    DIRECTION_INCOMING: "ACENCY_DIRECTION_INCOMING",
    DIRECTION_OUTGOING: "ACENCY_DIRECTION_OUTGOING",

    ACTION_SAVE: 'ACENCY_ACTION_SAVE',

    SAVE_REQUEST: 'ACENCY_SAVE_REQUEST',
    SAVE_SUCCESS: 'ACENCY_SAVE_SUCCESS',
    SAVE_FAILURE: 'ACENCY_SAVE_FAILURE',
   
    GETALL_REQUEST: 'ACENCY_GETALL_REQUEST',
    GETALL_SUCCESS: 'ACENCY_GETALL_SUCCESS',
    GETALL_FAILURE: 'ACENCY_GETALL_FAILURE',

    GETONE_REQUEST: 'ACENCY_GETONE_REQUEST',
    GETONE_SUCCESS: 'ACENCY_GETONE_SUCCESS',
    GETONE_FAILURE: 'ACENCY_GETONE_FAILURE',

    DELETE_REQUEST: 'ACENCY_DELETE_REQUEST',
    DELETE_SUCCESS: 'ACENCY_DELETE_SUCCESS',
    DELETE_FAILURE: 'ACENCY_DELETE_FAILURE',

    SHOW_NEW : 'ACENCY_SHOW_NEW',
    CLOSE_DETAIL : 'ACENCY_CLOSE_DETAIL',
};
