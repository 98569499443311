/* eslint-disable no-use-before-define */
import React, { Fragment } from 'react';
import ZnSelect from './ZnSelect';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions, leadActions } from '_actions';
import { makeStyles } from '@material-ui/styles';
import { configActions } from '_actions/config.actions';
import { Select, MenuItem, Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export {
  ZnUserSimpleSelect,
  ZnUserSelect,
  ZnEmailSelect,
  ZnUserValue,
}

const simpleSelectUseStyles = makeStyles({
  root: {
   // color: 'white',
  },
  select: {
   // color: 'inerith'
   
  },
  icon: {
   // color: 'white'
  },
})

const ZnUserSimpleSelect = () => {

  const classes = simpleSelectUseStyles();

  const users = useSelector(state => state.users.items)
  const userId = useSelector(state => state.config.userId);

  const dispatch = useDispatch();
  const handleChange = event => {
    dispatch(configActions.setUser(event.target.value));
  };

  // useEffect(() => {
  //   dispatch(leadActions.getAll());
  // }, [userId]);


  // useEffect(() => {
  //   if (users.length === 0) dispatch(userActions.getAll());
  // }, [users]);

  return (

    <Select
      classes={{
        root: classes.root,
        select: classes.select,
        icon: classes.icon,
      }}
      value={userId}
      onChange={handleChange}
      style={{width: "100%"}}
    >
      <MenuItem value="0">All users</MenuItem>
      {users.map((dep, k) => {

        return (
          <MenuItem key={k} value={dep.id}>{dep.name}</MenuItem>
        );

      })}
    </Select>

  );
}

const ZnUserSelect = (props) => {

  const {
    id,
    label,
    onChange,
    onKeyPress,
    value,
    error,
    helperText
  } = props;


  const users = useSelector(state => state.users.items)
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  useEffect(() => {
    if(!users) dispatch(userActions.getAll());
  }, []);

  useEffect(() => {
    if(!Array.isArray(users)) return;
    setItems(users.map(x => {
      return { code: x.id, label: x.name };
    }));
  }, [users]);

  return (
    <ZnSelect
      id={id}
      label={label}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
      error={error}
      helperText={helperText}
      items={items}
    />
  );
}

const ZnUserValue = (props) => {

  const { value } = props;

  const [label, setLabel] = useState('loading...');

  const users = useSelector(state => state.users.items)
  
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (users.length == 0) dispatch(userActions.getAll());
  // }, []);

  useEffect(() => {

    const id = parseInt(value);
    const user = users.filter(user => { return user && user.id === id }).shift();
    if (user && user.name) setLabel(user.name);
  }, [users, value]);


  return (
    <Fragment>{label}</Fragment>
  );
}



const ZnEmailSelect = (props) => {

  const {
    id,
    label,
    onChange,
    onKeyPress,
    value,
    error,
    helperText
  } = props;


  const users = useSelector(state => state.users.items)
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  useEffect(() => {
    if(!users) dispatch(userActions.getAll());
  }, []);

  useEffect(() => {
    if(!Array.isArray(users)) return;
    setItems(users.map(x => {
      return { code: x.email, label: x.email };
    }));
  }, [users]);

  return (
    <Fragment>
    {/* <ZnSelect
      
      onKeyPress={onKeyPress}
      value={value}
      error={error}
      helperText={helperText}
      items={items}
    /> */}
    <Autocomplete
        onChange={onChange}
        multiple
        id={id}
        options={items.map((option) => option.label)}
        defaultValue={[]}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="normal"
            label={label}
            placeholder="Enter email or select user and press ENTER"
          />
        )}
      />
      </Fragment>
  );
}



