export const constants = {

    STATUS_DRAFT: 'USER_STATUS_DRAFT',
    STATUS_OUTGOING: 'USER_STATUS_OUTGOING',
    STATUS_SENT: 'USER_STATUS_SENT',
    STATUS_ERROR: 'USER_STATUS_ERROR',



    DIRECTION_INCOMING: "USER_DIRECTION_INCOMING",
    DIRECTION_OUTGOING: "USER_DIRECTION_OUTGOING",

    ACTION_SAVE: 'USER_ACTION_SAVE',

    KICK_REQUEST: 'USER_KICK_REQUEST',
    KICK_SUCCESS: 'USER_KICK_SUCCESS',
    KICK_FAILURE: 'USER_KICK_FAILURE',
   
    GETALL_REQUEST: 'USER_GETALL_REQUEST',
    GETALL_SUCCESS: 'USER_GETALL_SUCCESS',
    GETALL_FAILURE: 'USER_GETALL_FAILURE',

    GETONE_REQUEST: 'USER_GETONE_REQUEST',
    GETONE_SUCCESS: 'USER_GETONE_SUCCESS',
    GETONE_FAILURE: 'USER_GETONE_FAILURE',

    DELETE_REQUEST: 'USER_DELETE_REQUEST',
    DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    DELETE_FAILURE: 'USER_DELETE_FAILURE',
};
