export function authHeader(addContentType = true) {

    var headers = new Headers();
    headers.append("Accept", "application/json");
    if(addContentType) {
        headers.append("Content-Type", "application/json");
    }

    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        headers.append("Authorization", 'Bearer ' + user.token);
    }
    
    return headers;
}