import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { actions, actions as company_clusterActions } from '../../../_model/company_cluster_setting_multicard/actions';
import {  Card, CardContent, CardHeader, Grid,  } from '@material-ui/core';
import ItemList from 'common/ItemList';

import { CompanyClusterSettingMulticardToolbar } from '.';





const CompanyClusterSettingMulticardList = () => {
  
  const company_cluster_setting_multicard = useSelector(state => state.company_cluster_setting_multicard);
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(company_clusterActions.getAll(null, 1, 10000));
  }, []);


  const itemMapper = (item) => {
    return {
      id: item.id,
      action :  "/company-cluster-setting-multicard/" + item.id,
      primary: `${item.name} `,
      secondary: `#${item.id}`,
    };
  }

  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>

      <Card>
        <CardHeader title="Multicard" action={<CompanyClusterSettingMulticardToolbar />} ></CardHeader>
        <CardContent>
        <ItemList items={company_cluster_setting_multicard.items ?? []} itemMapper={itemMapper} 
        onClick={handleClick}
         actionPath="/company_cluster_setting_multicard_kpi/"/>
      </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterSettingMulticardList;



