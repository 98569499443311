import { Card, CardContent, CardHeader, Grid, Table, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '_model/company_cluster_setting_report_value';
import { actions as filterActions } from '_model/filter';

const CompanyClusterSettingReportValueView = ({ id }) => {

  // const item = useSelector(state => state.company_cluster_setting_report_value.item);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(actions.getOne(id));
  //   let filter = {};
  //   filter['report_id'] = id;
  //   dispatch(filterActions.addFilter(filter))
  // }, [id]);

  // if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={`Report Valuea`} />
          <CardContent>
            tabella
          </CardContent>
        </Card>
      </Grid>

      
    </Grid>


  );
}


export default CompanyClusterSettingReportValueView;
