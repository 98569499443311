export const constants = {

    STATUS_DRAFT: 'CONFIG_KPI_STATUS_DRAFT',
    STATUS_OUTGOING: 'CONFIG_KPI_STATUS_OUTGOING',
    STATUS_SENT: 'CONFIG_KPI_STATUS_SENT',
    STATUS_ERROR: 'CONFIG_KPI_STATUS_ERROR',



    DIRECTION_INCOMING: "CONFIG_KPI_DIRECTION_INCOMING",
    DIRECTION_OUTGOING: "CONFIG_KPI_DIRECTION_OUTGOING",

    ACTION_SAVE: 'CONFIG_KPI_ACTION_SAVE',

    SAVE_REQUEST: 'CONFIG_KPI_SAVE_REQUEST',
    SAVE_SUCCESS: 'CONFIG_KPI_SAVE_SUCCESS',
    SAVE_FAILURE: 'CONFIG_KPI_SAVE_FAILURE',
   
    GETALL_REQUEST: 'CONFIG_KPI_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONFIG_KPI_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONFIG_KPI_GETALL_FAILURE',

    GETONE_REQUEST: 'CONFIG_KPI_GETONE_REQUEST',
    GETONE_SUCCESS: 'CONFIG_KPI_GETONE_SUCCESS',
    GETONE_FAILURE: 'CONFIG_KPI_GETONE_FAILURE',

    DELETE_REQUEST: 'CONFIG_KPI_DELETE_REQUEST',
    DELETE_SUCCESS: 'CONFIG_KPI_DELETE_SUCCESS',
    DELETE_FAILURE: 'CONFIG_KPI_DELETE_FAILURE',

    SHOW_NEW : 'CONFIG_KPI_SHOW_NEW',
    CLOSE_DETAIL : 'CONFIG_KPI_CLOSE_DETAIL',
};
