/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useState } from 'react';


const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export default function ZnSelect(props) {

  const classes = useStyles();

  const { id, label, onChange, onKeyPress, value, error, helperText, items, itemsDisabled = [], displayValue = true } = props;

  const [displayedValue, setDisplayedValue] = useState('');

  useEffect(() => {
    const code = items.filter(item => { return item && item.code === value }).shift();
    setDisplayedValue(code);
  }, [value])

  const getItems = () => {
    return items.filter((item => {
      return !itemsDisabled.includes(item.code);
    }));
  }


  return (
    <Autocomplete
      id={id}
      // value={value}
      value={displayedValue || ''}
      clearOnEscape={true}
      onKeyPress={onKeyPress}
      onChange={onChange}
      margin="normal"
      options={getItems()}
      classes={{
        option: classes.option,
      }}
      autoSelect
      autoHighlight
      getOptionLabel={option => typeof option === 'string' ? option : option.label}
      renderOption={option => typeof option === 'string' ? option : option.label}
      renderInput={params => (
        <TextField
          {...params}
          value={displayedValue || ""}
          variant="outlined"
          margin="normal"
          label={label}
          error={error}
          helperText={helperText}
          fullWidth
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
}
