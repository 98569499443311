import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions,  } from '../../../_model/config_kpi/actions';
import {  Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import ItemList from 'common/ItemList';
import { ConfigKpiToolbar } from '.';

const ConfigKpiList = () => {
  const config_kpi = useSelector(state => state.config_kpi);
  const dispatch = useDispatch();
  

  
 
  useEffect(() => {
    dispatch(actions.getAll(1, 500));
  }, []);


  // item mapping



  const itemMapper = (item) => {
    const parser = item.parser === null ? '' : `------------------------>${item.parser}`
    return {
      id: item.id,
      action :  "/company-cluster-setting-kpi/" + item.id,
      primary: `${item.name} ${parser}`,
      secondary: `#${item.id}`,
    };
  }


  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={2}>
    <Grid item xs={12}>

      <Card>
        <CardHeader title="Kpi" action={<ConfigKpiToolbar />} ></CardHeader>
        <CardContent>
        <ItemList items={config_kpi.items ?? []} itemMapper={itemMapper} 
        onClick={handleClick}
         actionPath="/config_kpi/"/>
      </CardContent>
      </Card>
      </Grid>
    </Grid>
  );
};

export default ConfigKpiList;



