import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {default as MuiToolbar} from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Toolbar = ({actions}) => {

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleAddNew = () => {
    dispatch(actions.showNew());
  }

  return (
    <div className={classes.root}>
      <MuiToolbar disableGutters={true} style={{ minHeight: "auto", backgroundColor: "#f5f5f5", color: "black" }}>
          <Typography variant="h6" className={classes.title}></Typography>
          <Button color="inherit" onClick={handleAddNew}>Add new</Button>
        </MuiToolbar>
    </div>
  );
}

export default Toolbar;