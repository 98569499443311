import React from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingSubcategoryList, { CompanyClusterSettingSubcategoryDetail, CompanyClusterSettingSubcategoryView } from './components';

const CompanyClusterSettingSubcategory = () => {

  const { company_cluster_setting_subcategory_id } = useParams();

  return (
    <>
    {company_cluster_setting_subcategory_id ? ( 
      <CompanyClusterSettingSubcategoryView id={company_cluster_setting_subcategory_id} />
    ) : (
      <>
      <CompanyClusterSettingSubcategoryList />
      <CompanyClusterSettingSubcategoryDetail />
      </>
    )}
      
    </>
  );

};

export default CompanyClusterSettingSubcategory;
