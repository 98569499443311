import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '_model/screenshot';
import { actions as filterActions } from '_model/filter';

const ScreenshotView = ({ id }) => {

  const item = useSelector(state => state.screenshot.item);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.getOne(id));
    let filter = {};
    filter['screenshot_id'] = id;
    dispatch(filterActions.addFilter(filter))
  }, [id]);

  if (!item) return null;

  return (

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Screenshot" />
          <CardContent>
          <Typography>#{item.id} {item.name} - {item.weight}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
      
      </Grid>
    </Grid>


  );
}


export default ScreenshotView;
