import { constants  } from './constants';

const initialStatus = {
  successAction: false,
  items: [],
  total : 0,
  item: null,
  loading: false,
  error: null,

};

export function reducer(state = initialStatus, action) {


  switch (action.type) {
    case constants.KICK_REQUEST:
      return {
        ...state,
        successAction: false,
        doingAction: true,
        actionError: false,
        actionErrorMsg: null,

      };

    case constants.KICK_SUCCESS:
      const key = state.items.findIndex(item => item.id === action.constants.id);
      let ii = state.items;

      if (key in ii) {
        ii.splice(key, 1, action.item);
      } else {
        ii.push(action.item);
      }

      return {
        ...state,
        successAction: true,
        doingAction: false,
        actionError: false,
        actionErrorMsg: null,
        items: ii,
      };
      break;

    case constants.KICK_FAILURE:
      return {
        ...state,
        successAction: false,
        doingAction: false,
        actionError: true,
        actionErrorMsg: action.error,
      };

    case constants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case constants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.items,
        total: action.meta.total,
        loading: false
      };
    case constants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case constants.GETONE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case constants.GETONE_SUCCESS:
      return {
        ...state,
        item: action.item,
        loading: false
      };
    case constants.GETONE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    default:
      return state;
  }
}