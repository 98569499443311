import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import { TextField } from '@material-ui/core';
import { actions } from '_model/company_cluster_setting_kpi';



const defaultFormState = {
  loaded: false,
  isValid: false,
  touched: {},
  errors: {},
  values: {
    name: '',
  },
};

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },

 
};


const CompanyClusterSettingKpiDetail = props => {

  const { company_cluster_setting_kpi } = props;


  const item = useSelector(state => state.company_cluster_setting_kpi.item);

  useEffect(() => {
    if (item !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [item]);

  const [open, setOpen ] = useState(false)

  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };


  const handleFieldChange = (fieldName, value) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [fieldName]: value,

      },
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {
    if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    setFormState(formState => ({
      ...formState,
      touched: schema
    }));


    dispatch(actions.save(formState.values));
  };

  const handleDelete = event => {
    event.preventDefault();
    dispatch(actions._delete(formState.values.id));
  };

  const doingAction = useSelector(state => state.company_cluster_setting_kpi.doingAction);
  const successAction = useSelector(state => state.company_cluster_setting_kpi.successAction);
  const actionErrorMsg = useSelector(state => state.company_cluster_setting_kpi.actionErrorMsg);

  

  useEffect(() => {
    console.log("SUCCESS ACTION", successAction);
    if (successAction === true) handleClose();
  }, [successAction]);

  const handleClose = () => {
    if (!doingAction) {
      dispatch(actions.closeDetail());
      setFormState(defaultFormState);
    }
  };

  const [formState, setFormState] = useState(defaultFormState);

  const formValidation = () => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }

  useEffect(() => {
    formValidation();
  }, [formState.values]);

  useEffect(() => {

    console.log(item);
    if (item) {
      setFormState({
        ...formState,
        values: {...item},
      });
    } else {
      setFormState(defaultFormState);
    }
  }, [item]);


  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      isValid: actionErrorMsg ? false : true,
      errors: actionErrorMsg ? actionErrorMsg.errors : {},
    }));
  }, [actionErrorMsg]);

 
 


  return (
    <div>

      <Dialog
        fullWidth={false}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {company_cluster_setting_kpi ? "CompanyClusterSettingKpi detail" : "New company_cluster_setting_kpi"}
        </DialogTitle>
        <DialogContent>
          
          <TextField
            autoFocus
            name="name"
            label="Name"
            value={formState.values.name}
            variant="outlined"
            onChange={handleChange}
            onKeyPress={handleSubmit}
          />

          <TextField
            name="weight"
            label="Weight"
            value={formState.values.weight}
            variant="outlined"
            onChange={handleChange}
            onKeyPress={handleSubmit}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CompanyClusterSettingKpiDetail.defaultProps = {
  company_cluster_setting_kpi: {},
}
export default CompanyClusterSettingKpiDetail;
