import { constants } from './constants';

const initalState = {
  alerts: [] 
};


export function reducer(state = initalState, action) {

  switch (action.type) {
    case constants.SUCCESS:
      return {
        ...state,
        alerts: [
            ...state.alerts,
            {
                key: action.key,
                variant: 'success',
                message: action.message
            },
        ]
      };
    case constants.ERROR:
      return {
        ...state,
        alerts: [
            ...state.alerts,
            {
                key: action.key,
                variant: 'error',
                message: action.message
            },
        ]
      };
      break;
    case constants.CLEAR:
      return {
        ...state,
        alerts: state.alerts.filter(
          alert => alert.key !== action.key,
        ),
      };
    default:
      return state;
  }
}