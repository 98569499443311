import React from 'react';
import { useParams } from 'react-router';
import CompanyClusterSettingMulticardList, { CompanyClusterSettingMulticardDetail, CompanyClusterSettingMulticardView } from './components';

const CompanyClusterSettingMulticard = () => {

  const { company_cluster_setting_multicard_id } = useParams();

  return (
    <>
    {company_cluster_setting_multicard_id ? ( 
      <CompanyClusterSettingMulticardView id={company_cluster_setting_multicard_id} />
    ) : (
      <>
      <CompanyClusterSettingMulticardList />
      <CompanyClusterSettingMulticardDetail />
      </>
    )}
      
    </>
  );

};

export default CompanyClusterSettingMulticard;
