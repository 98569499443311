import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../_model/company_cluster_setting_multicard_kpi/actions';
import {  Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch, TextField} from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';


const defaultFormState = {
  loaded: false,
  isValid: false,
  touched: {},
  errors: {},
  values: [],
};

const Multidetail = props => {

  const repo = useSelector(state => state.company_cluster_setting_multicard_kpi);
  const dispatch = useDispatch();

  const [formState, setFormState] = useState(defaultFormState);

  useEffect(() => {
    const items = repo.items.map((item)=> {
        return {
          id: item.id,
          kpi_id: item.kpi_id,
          checked: Number.isInteger(item.id),
          name: item.name,
          weight: item.weight,
      }
    });
    setFormState(formState => ({
      ...formState,
      values: items,
      
    }));
     
  }, [repo.items]);
  

  useEffect(() => {
    dispatch(actions.getAll([], 1, 1000));
  }, []);

  const handleChange = (event) => {
    event.persist();
    handleFieldChange(event.target.name, event.target.type === 'checkbox'
      ? event.target.checked
      : event.target.value);
  };


  const handleFieldChange = (fieldName, value) => {
    const key = fieldName.split('_').pop();
    const name = fieldName.split('_').shift();
    let vv = [...formState.values];
    
    vv[key][name] = value;
    setFormState(formState => ({
      ...formState,
      values: vv,
      touched: {
        ...formState.touched,
        [fieldName]: true
      }
    }));
  };

  const handleSubmit = event => {
    //if (!formState.isValid) return;

    if (event.type === 'keypress' && event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
   

    dispatch(actions.save(formState.values));
  };

  const [showAll, setShowAll] = useState(false);

  const toogleShowAll = () => {
    console.log(formState);
    setShowAll(!showAll);
  }

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="MulticardKpi" action={<FormControlLabel
            control={<Switch checked={showAll} onChange={toogleShowAll} />}
            label="Show all KPI"
            labelPlacement="end"
          />} 
           ></CardHeader>
          <CardContent>
            <List  >
              {formState.values.map((item, k) => {
                
                if (!showAll && !formState.values[k].checked) return null;
                return (
                  <Fragment key={k}>
                    <ListItem selected={formState.values[k].checked === 1}>
                      <ListItemAvatar>
                        <Checkbox
                        checked={formState.values[k].checked}
                        onChange={handleChange}
                        name={`checked_${k}`}
                        color="primary"
                         ></Checkbox>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={formState.values[k].name}
                        secondary={formState.values[k].id}
                      />
                      <ListItemSecondaryAction>
                      <TextField
                        autoFocus
                        name={`weight_${k}`}
                        label="Weight"
                        value={formState.values[k].weight || ''}
                        variant="outlined"
                        onChange={handleChange}
                        onKeyPress={handleSubmit}
                      />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider></Divider>
                  </Fragment>
                )})}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    
    </>
  );
}


export default Multidetail;
