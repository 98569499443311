import { constants  } from './constants';


export const actions = {
    setToolbar,
    

};


function setToolbar(toolbar) {

    return {
        type: constants.SET_TOOLBAR,
        toolbar
      };
    
    
}



