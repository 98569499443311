import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '_actions';
import { withSnackbar } from 'notistack';


const Notifier = props => {

  // const alerts = useSelector(state => state.alert.alerts);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (alerts) {
  //     alerts.forEach(function (_alert) {
  //       dispatch(alertActions.clear(_alert.key));
  //       props.enqueueSnackbar( _alert.message, { variant: _alert.variant });
  //     });
  //   }
  // }, [alerts]);
  return '';
};

export default withSnackbar(Notifier);

