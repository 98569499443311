import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../_model/company_cluster_setting_report/actions';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import ItemList from 'common/ItemList';

import { CompanyClusterSettingReportToolbar } from '.';


const CompanyClusterSettingReportList = () => {
  const company_cluster_setting_report = useSelector(state => state.company_cluster_setting_report);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getAll([], 1, 1000));
  }, []);

  const itemMapper = (item) => {
    return {
      id: item.id,
      action: "/company-cluster-setting-report/" + item.id,
      primary: `${item.name} `,
      secondary: `#${item.id} (${item.setting_id})`,
    };
  }

  const handleClick = (id) => {
    dispatch(actions.showNew(id));
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>

        <Card>
          <CardHeader title="Report" action={<CompanyClusterSettingReportToolbar />} ></CardHeader>
          <CardContent>
            <ItemList items={company_cluster_setting_report.items ?? []} itemMapper={itemMapper}
              onClick={handleClick}
              actionPath="/company_cluster_setting_report_kpi/" />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompanyClusterSettingReportList;



